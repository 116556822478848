.school-permission-form {
  .green-checked {
    color: #52c41a
  }

  .red-checked {
    color: #f5222d      
  }

  .ant-switch-checked {
    background-color: #52c41a !important;
  }

  .ant-switch {
    margin-left: 10px;
    margin-right: 10px;
    background-color: #f5222d;
  }

  .ant-row {
    margin-top: 10px;
    margin-bottom: 10px;

    .ant-col:first-child {
      font-weight: bold;
    }
  }
}
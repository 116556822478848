$footer_height: 40px;
$help_button_height: 75px;
$help_button_width: 120px;
$header-height: 105px;
$header-height-in-widget: 65px;
$multi-selection-div-height: 115px;
$ant-input-height: 20px;
$v-spacing-size: 10px;
$border-color: #e8e8e8;
$pause-quotas-font-size: 11px;
$pause-quotas-padding-bottom: 5px;
$pause-quotas-margin-top: 5px;
$pause-quota-total-cell-part-height: 55px;

.shifts-manager {
  .v-spacing {
    display: flex;
  }

  *,*:focus,*:hover{
    outline:none;
  }

  .table-and-header {
    height: calc(100vh - 150px);
  }

  .shift-manager-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    z-index: 9;
    position: sticky;
    top: 0;
    padding-top: 10px;
  }

  .shifts-widget-cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 9999999;
  }

  .table-in-multi-select-mode{
    height: calc(100% - #{$header-height} - (#{$multi-selection-div-height} - 2*#{$v-spacing-size}));
  }

  table {
    display: block;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 255px);
    border-collapse: separate;
    border-spacing: 0px 10px;
    table-layout: fixed;
    overflow-x: auto;
    overflow-y: auto;

    tr {
      .loading-shift-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .loading-backdrop {
          background: #3c8dbc;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0.9;
        }

        .loading-text {
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: bold;
          z-index: 1;

          .loading-icon {
            width: 30px;
            height: 30px;
            border-width: 3px;
          }

          span {
            margin-left: 10px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }

      th {
        background: white;
        z-index: 1;
        text-align: center;

        &:nth-child(n + 2) {
          position: sticky;
          top: 0px;
          min-width: 60px;
          cursor: pointer;
        }

        &:first-child {
          border-spacing: 0px;
          position: sticky;
          left: 0px;
          min-width: 370px;
          z-index: 2;
        }
      }
    }

    tbody {
      tr {
        &.row-background td {
          background: #efefef;
        }

        &.row-no-background td {
          background: #fff;
        }

        &:nth-child(even) {
          background-color: #ececec;

          td {
            height: 1px;
            background-color: #ececec;
          }
        }

        &:hover {
          td {
            background-color: #cde9ff;
          }
        }

        td {
          border-right: 1px solid white;
          cursor: pointer;
          min-width: 64px;

          &.highlighted {
            background: #cde9ff;
          }

          &.in-weekend {
            opacity: 0.5;
          }

          &:first-child {
            background-color: white;
            position: sticky;
            left: 0;
            min-width: 200px;
            z-index: 99;
            border: 2px solid lightgrey;
            box-shadow: -10px 0px  white;
            border-radius: 5px;
          }

          &:not(:first-child) {
            padding: 3px;

            &>div {
              text-align: center;
              line-height: 1.25;
              border: 3px solid transparent;
              border-radius: 5px;
              min-height: 70px;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
  
              &.spacing-row {
                height: 10px;
                min-height: unset;
                border: none;
                background-color: white;
              }
  
              .time-range {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2px;
                font-size: 10px;
              }
            } 
          }

          &.disabled {
            cursor: default;
          }

          &:hover {
            background: #cde9ff;
          }

          &.selected>div {
            border: 3px solid #3c8dbc;

            .shift-selection-mode-cover {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              cursor: pointer;
              z-index: 1;
            }
          }

          &.shift-absence>div {
            border: 3px solid #d32f2f;
          }

          .empty-shift-item {
            outline: none;
          }

          &.has-internship {
            border-top: 2px solid #39A7FF;
            border-bottom: 2px solid #39A7FF;
            z-index: 0;

            &.has-internship-end {
              border-right: 2px solid #39A7FF;
              border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;
            }

            &.has-internship-start {
              border-left: 2px solid #39A7FF;
              border-bottom-left-radius: 10px;
              border-top-left-radius: 10px;
            }
          }

          &.has-internship-draft {
            border-top: 2px dashed #39A7FF;
            border-bottom: 2px dashed #39A7FF;
            z-index: 0;

            &.has-internship-end-draft {
              border-right: 2px dashed #39A7FF;
              border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;
            }

            &.has-internship-start-draft {
              border-left: 2px dashed #39A7FF;
              border-bottom-left-radius: 10px;
              border-top-left-radius: 10px;
            }
          }
        }
      }

      tr.row-background td.disabled:hover {
        background: #efefef;
      }

      tr:nth-child(even) td.disabled:hover {
        background: #fff;
      }
    }
  }

  .shifts-form .ant-input-prefix .fa {
    color: #c8c8c8;
  }

  .legend-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .shift-row {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .shift-row-title {
      white-space: normal;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 5px;
      font-size: 12px;
    }
  }

  .shift-error {
    background: #d32f2f;
    position: relative;

    svg {
      position: absolute;
      color: #d32f2f;
      font-size: 12px;
      top: 12px;
      right: 7px;
      cursor: pointer;
    }

    .shift-event-code-input,
    span {
      color: #fff;
    }
  }

  .shift-error-event-only {
    background: #d32f2f;
    position: relative;

    svg {
      position: absolute;
      color: #ffffff;
      font-size: 12px;
      top: 27px;
      right: 7px;
      cursor: pointer;
    }
  }

  .event-codes-legend {
    font-size: 11px;
    color: #bbb;
    cursor: default;
    max-width: 600px;

    &:first-child {
      margin-bottom: 5px;
    }

    b {
      color: #357fa9;
    }
  }

  .dates-details-header {
    display: flex;
    flex-shrink: 0;
    margin-right: 0;

    .dates-range-info {
      display: flex;
      align-items: center;
      cursor: default;
      margin: 0 10px;
    }

    .dates-range-info svg {
      margin-right: 10px;
    }
  }

  .export-buttons {
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  .with-transition {
    transition: margin-right 0.2s ease-in-out;
  }

  .simili-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-width: 168px;
    padding-left: 11px;
    padding-right: 11px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:focus {
      border-color: #1890ff;
    }

    &:hover{
      border-color: #1890ff;
    }
  }
}

.loading-shifts-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  .backdrop {
    position: absolute;
    background: #555;
    opacity: 0.1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .loading-ring {
    top: 29%;
    z-index: 1;
  }
}

.fill-content {
  width: 100%;
  height: 69vh;

  .table-loading-ring {
    position: relative;
    left: 50%;
    top: calc(50% - (15vw/2));
    border: 5px solid transparent;
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    border-top-color: #3c8dbc;;
    border-bottom-color: #3c8dbc;;
    animation: loading-rotate .8s ease infinite;
  }
}

.student-fiche-right-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  .internship-total-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;

    label {
      font-size: 11px;
      color: #999;
      white-space: nowrap;
    }

    p {
      font-weight: bold;
      font-size: 13px;
    }
  }
}

.shifts-export-title {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  cursor: default;
}

.shifts-export-options {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 8px 0;
    display: flex;
    align-items: center;

    input {
      border: none;
    }
  }
}

.shift-cell{
  padding: 8px 5px 8px 5px;
}

.shift-manager-footer{
  margin: 5px;
}

.table-footer {
  height: 20px;

  .total-data-text {
    padding-right: 15px;
  }

  div {
    font-weight: bold;

    &.page-index {
      padding-top: 2px;
    }
  }
}

.empty-shifts-table{
  height: calc(100% - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-shifts-table-multi-select-mode{
  height: calc(100% - #{$header-height} - (#{$multi-selection-div-height} - 2*#{$v-spacing-size}));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-and-body-in-widget{
  height: calc(100vh - #{$footer_height});
  margin: 0px 10px 0px 10px;

  table{
    height: calc(100% - #{$header-height-in-widget});
  }
}

.shift-manager-footer-in-widget{
  margin: 5px 10px 5px 10px;
}

.pause-quotas-header {
  margin-top: $pause-quotas-margin-top;
  font-size: $pause-quotas-font-size;
  height: $pause-quota-total-cell-part-height;

  padding-bottom: $pause-quotas-padding-bottom;

  .ant-col-14 {
    border-left: 2px solid $border-color;
    padding-left: 5px;
  }

  .ant-col-18 {
    display: flex;
    flex-direction: row;
    padding-left: 2px;
  }

  .ant-col-10 {
    height: calc(#{$pause-quota-total-cell-part-height} * (4 / 5));
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .total-column-container {
      width: calc(100% - 10px);
      height: 100%;
      margin-right: 5px;
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.pause-quotas-th {
  border-right: 2px solid $border-color;
}

.pause-quota-cell-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pause-quota-cell {
  width: 86px;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;

  .pause-quota-period-row {
    .ant-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      background-color: #fff;

      .ant-input {
        height: $ant-input-height;
        border:  0px;
        border-right: 1px solid #d9d9d9;
        border-radius: 0px;
        background-color: transparent;
        padding: 4px;
        text-align: center;
        

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .pause-quota-times-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .pause-quota-event-row {
    .ant-input {
      height: $ant-input-height;
      padding: 0px;
      border: 0px;
      border-bottom: 1px dashed lightgrey;
      text-align: center;
      text-overflow: ellipsis;
      background-color: transparent;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.time-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 10px;
    font-weight: bold;
  }

  input {
    border: 0px;
    padding: 0px;
    height: 12px;
    width: 15px;
    font-size: 9px;
    text-align: center;
  }
}

.pause-shift-error {
  .ant-input {
    background-color: white;
  }
}

.simple-total-header-cell {
  margin-top: $pause-quotas-margin-top;
  font-size: $pause-quotas-font-size;
  padding-bottom: $pause-quotas-padding-bottom;
}

.table-footer {
  margin-right: $help_button_width;
}

@media only screen and (max-height : 915px) {
  .shift-manager-footer-in-widget{
    .table-footer{
      margin-right: 0px;
    }
  }
}

.internship-card-container {
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .internship-card-title {
    height: 100%;
    white-space: normal;
    display: flex;
    align-items: center;

    .internship-information {
      display: flex;
      flex-direction: column;
      text-align: left;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;

        span {
          &:first-child {
            font-size: small;
          }
        }
      }

      .regular-image {
        display: flex;
        margin-right: 5px;
        min-width: 20px;
        justify-content: center;
      }

      .last-text {
        font-size: small;
        color: grey;
      }
    }
  }
  
  .internship-checkbox {
    position: absolute;
    top: 5px;
    left: calc(100% - 22px);
    display: flex;
    justify-content: flex-end;
    border-spacing: 0px;
  }

  .internship-card-buttons {
    height: 100%;
    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    margin-left: 5px;
    font-size: 12px;
      
    svg {
      margin-bottom: 10px;
    }

    button {
      margin-top: 0px;

      svg {
        margin-bottom: 0px;
      }
    }
  }

  .in-draft-mode {
    margin-right: 15px;
  }
}

.table-first-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 7px;

  b {
    width: 100%;
  }
}
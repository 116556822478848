.schedule-bonus-table {
  .ant-btn {
    span {
      margin-left: 5px;
    }
  }

  .ant-table {
    tbody {
      tr {
        td {
          .ant-btn {
            margin: 0px;
          }
        }
      }
    }
  }
}
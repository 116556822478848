.places-allocation-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 175px);
  width: 100%;
}

.places-allocation-settings {
  height: 100%;
  max-width: 200px; 
  min-width: 150px;
  width: 20%;
}

.places-allocation-tables-container {
  height: 100%;
  width: 100%;
  min-width: 450px;
  padding: 5px;
  padding-left: 10px;
}

.search-input-with-list {
  .ant-input{
    border-radius: 4px 4px 0px 0px;
  }
}

.multi-tables-container {
  width: 100%;
  height: calc(100% - 85px);
  overflow-y: scroll;
}

.multi-tables-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: larger;
  }
}
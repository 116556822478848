.evaluation-questions-and-answers-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
  background: #f5f5f5;
  border-radius: 5px;

  &.expanded {
    max-height: 100vh;
  }

  .body {
    display: flex;
    flex-direction: column;

    .question-row {
      display: flex;
      align-items: flex-start;

      &:first-child {
        padding-top: 20px;
      }

      .question-column {
        flex: 1;
        padding: 0 20px;

        .question-item {
          display: flex;
          width: 100%;
          align-items: flex-start;
          margin-bottom: 5px 5px 15px 5px;
          cursor: default;
          padding: 10px;

          .question-text {
            padding-right: 10px;
            flex: 1
          }

          .question-answer {
            font-size: 20px;
            display: flex;

            svg {
              color: #bbb;
            }

            &.red {
              svg {
                color: #cf1322;
              }
            }

            &.orange {
              svg {
                color: #faad14;
              }
            }

            &.green {
              svg {
                color: #389e0d;
              }
            }
          }
        }
      }
    }
  }
}
.existing-student-details {
  list-style: none;
  padding: 0;

  li {
    margin: 5px 0;
    display: flex;

    label {
      width: 35%;
    }
  }
}

.order-by-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  .ant-btn {
    cursor: pointer;
    background: transparent;
    outline: none;
    padding: 0 11px;
    border-radius: 0px 4px 4px 0px;
    margin-right: 10px;
  }
  
  .ant-select-selection {
    border-radius: 4px 0px 0px 4px;
  }
}
.fc-daygrid-day-frame:hover {
  background: #C9E1FF;
}

.calendar-disable {
  .fc-customButton-button {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;

    &:hover {
      color: rgba(0, 0, 0, 0.25)!important;
      border-color: #d9d9d9 !important;
      background-color: #f5f5f5 !important;
      box-shadow: unset !important;
    }

    &:focus {
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: #d9d9d9 !important;
      background-color: #f5f5f5 !important;
      box-shadow: unset !important;
    }
  }
}

@media only screen and (max-width: 620px) {
  .fc .fc-toolbar.fc-footer-toolbar  {
    position: sticky;
    bottom: 15px;
    z-index: 99;
  }

  .modal-acts-div {
    div::-webkit-scrollbar {
      width: 5px;
      height: 100px;
    }

    div::-webkit-scrollbar-thumb {
      background: #c0c0c0;
      border-radius: 10px;
    }

  }
}
/* SMART TABLE */
.smart-table {
  table {
    width: 100%;
    overflow: scroll;
    border-collapse: collapse;
    margin-top: 5px;
    table-layout: fixed;

    thead tr th {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      text-align: left;

      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 30px;
        padding-top: 3px;
        padding-left: 3px;
        padding-right: 3px;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        font-weight: bold;
        user-select: none;
      }

      &.order-up > div {
        border-top: 2px solid #3c8dbc;
      }

      &.order-down > div {
        border-bottom: 2px solid #3c8dbc;
      }

      &:not(.actions) {
        cursor: pointer;
      }

      .smart-table-column-header {
        display: flex;
        align-items: center;
        position: relative;
        overflow: visible;

        .column-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 80%;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #ececec;

        &:first-child {
          border-top: 1px solid #ececec;
        }

        &:hover {
          background-color: #cde9ff;
        }

        &.new-data {
          border: 1px solid #3c8dbc;
        }

        &:focus {
          border: 1px solid red;
        }
      }

      td {
        padding-left: 3px;
        padding-right: 3px;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.incorrect-data {
          background-color: rgba(255, 0, 0, 0.5);

          input::placeholder {
            color: white;
          }
        }

        div.disabled-data {
          cursor: not-allowed;
        }

        input {
          padding-left: 0px;
          border: 0px;
          font-size: inherit;
          height: 30px;
          width: 100%;
          background: transparent;
          cursor: text;
        }

        .ant-select {
          width: 100%;
          height: 23px;

          .ant-select-selection {
            background: transparent;
            border: none;
            cursor: text;

            .ant-select-arrow {
              right: 0px;
            }
          }

          .ant-select-selection-selected-value {
            padding-right: 15px;
          }

          .ant-select-selection[aria-expanded='false']
            .ant-select-selection__rendered {
            margin-left: 0px;
            margin-right: 0px;
          }
        }

        span.ant-calendar-picker {
          width: 100%;

          i {
            display: none;
          }
        }
      }
    }
  }

  .save-hint {
    font-size: 20px;
    margin-left: 20px;
    margin-top: 5px;
  }

  .footer {
    & > div {
      padding: 15px 3px 60px;
    }

    div {
      font-weight: bold;

      &.page-index {
        padding-top: 2px;
      }
    }
  }

  &.singleton-data {
    .hidden-sigleton-data {
      display: none !important;
    }

    table thead tr th {
      &:not(.actions) {
        cursor: inherit !important;
      }

      &.order-up > div {
        border-top: 2px solid transparent !important;
      }

      &.order-down > div {
        border-bottom: 2px solid transparent !important;
      }
    }
  }

  .loading-text {
    color: 99ee9e;
  }

  .error-message {
    color: #f44336;
  }

  .secondary-buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-left: 10px;

    &.in-front {
      margin-left: 0;
      margin-right: 10px;
    }

    button {
      display: flex;
      align-items: center;
    }
  }

  .smart-table-image {
    width: 20px;
    height: 20px;
  }

  .tags-list-smart-table {
    display: flex;
    align-items: center;

    .not-selected {
      opacity: 0.6;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      outline: none;
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }

      span {
        cursor: pointer;
      }
    }
  }
}

.new-dropdown-option {
  border-top: 1px solid #ddd;
}

.green-button {
  background-color: green !important;
  color: white !important;
}

.institution-parameters {
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .parameters-navbar {
        width: 15%;
        min-width: 200px;


        .list-parameters {
            
            .ant-list-item:hover {
                background-color: lightgray !important;
                cursor: pointer;
            }
        }
    }
}

.institution-identity, .institution-student-data, .institution-options {
    min-width: 400px;
    margin-bottom: 20px;
}

.name_institution_option {
    text-align: center;
}

.parameters {
    padding-left: 20px;
    width: 85%;
}

.name-header-institution-option {
    cursor: default;
    text-align: left !important;
    width: 80%;
}

.switch-header-institution-option {
    cursor: default;
    text-align: center !important;
    width: 20%;
}

.switch-institution-option {
    text-align: center;
}

#star-icon {
    color: #03dbfc;
}
.supervised-sectors-container {
  .actions-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .list-item-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      width: 90%;
    }
  }

  .ant-input-search {
    max-width: 250px;
  }

  .supervised-sectors-list {
    height: calc(100vh - 180px);
    margin: 0px;
    overflow-y: auto;
  }
}

.supervised-sectors-modal {
  .ant-modal-body {
    padding: 0px;
  }
}
.alink{
  color: #3c8dbc !important;
  cursor: pointer;
}

.changelog-link-style{
  bottom: 10px;
  width: 100%;
  padding: 15px;

  span{
    width: fit-content;
  }

  a{
    width: fit-content;
  }
}
.internship-validation-drawer {

  .ant-drawer-content-wrapper {
    transition: width 0.2s ease-in-out;
  }

  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;

    .ant-drawer-body {
      flex: 1;
      height: calc(100% - 55px);

      .ant-tabs {
        display: flex;
        flex-direction: column;
        height: 100%;

        .ant-tabs-content {
          flex: 1;
        }
      }
    }
  }

}

.internship-validation-summary {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  h3 {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    padding: 20px 0;
    margin: 0;
  }

  &.has-two-tables {
    h3 {
      padding: 20px 0 10px 0;
    }
  }

  .table-wrapper {
    table {
      width: 100%;
      table-layout: fixed;

      th {
        font-weight: bold;
      }

      .col-date {
        width: 15%;
      }

      &.has-exact-location {
        .col-date {
          width: 8%;
        }

        .col-start {
          width: 20%;
        }

        .col-end {
          width: 20%;
        }

        .col-pause {
          width: 15%
        }

        .col-total {
          width: 12%
        }

        .col-total-sum {
          width: 78%
        }

        .col-total-pause {
          width: 15%;
        }

        .col-total {
          width: 10%
        }
      }

      .col-exact-location {
        width: 30%;
      }

      .col-start {
        width: 25%;
      }

      .col-end {
        width: 25%;
      }

      .col-pause {
        width: 15%
      }

      .col-total {
        width: 20%
      }

      .col-total-pause {
        width: 15%;
      }

      .col-total-sum {
        width: 65%
      }

      .absent {
        color: #d32f2f;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        font-weight: bold;
      }

      .absence-non-justifie {
        color: #d32f2f;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        font-weight: bold;
      }

      &.total-summary {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .no-shifts-warning {
      color: #d32f2f
    }

    .shifts-table {
      max-height: 80%;
      overflow-y: auto;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;

      &.no-border {
        border-top: none;
        border-bottom: none;
      }

      table {
        tr:nth-child(2n) {
          background: #efefef
        }
      }
    }
  }

  .acts-table-wrapper {
    display: flex;
    flex-direction: column;

    .acts-table-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .category-label {
        font-size: 18px;
        font-weight: bold;
        color: #666;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .has-schedule-component {
          .act-value {
            color: #ff9536;
            padding-right: 5px;
          }

          svg {
            margin-bottom: 4px;
            color: #ff9536;
          }
        }

        li {
          display: flex;
          align-items: center;

          &:nth-child(2n+1) {
            background: #efefef
          }

          .act-name {
            flex: 1;
            padding-left: 20px;
          }

          .act-value {
            font-weight: bold;
            padding: 0 20px;
          }
        }

        .act-type-total {
          border-top: 1px solid #000;
        }
      }

      .schedule-complements-container {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
  
          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
  
            &:nth-child(2n+1) {
              background: #efefef
            }
  
            .act-name {
              flex: 1;
              padding-left: 20px;
            }
  
            .act-value {
              font-weight: bold;
              padding: 0 20px;
            }
          }
        }
      }
    }



    table {
      width: 100%;

      th {
        font-weight: bold;
      }

      .col-date {
        width: 15%;
      }

      tr:nth-child(2n) {
        background: #efefef
      }

      &.total-summary {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .acts-table-list {
      max-height: 80%;
      overflow-y: auto;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    }
  }

  .buttons-wrapper {
    padding: 20px 0;
    display: flex;
    align-items: center;
    bottom: 10px;

    button {
      margin-right: 15px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      .loading-icon {
        margin-top: 0;
      }
    }

    .validated-status {
      text-transform: uppercase;
      font-size: 14px;
      color: rgb(126, 183, 62);
      font-weight: bold;
      margin-right: 20px;
      cursor: default;
    }

    .validated-enabled-on {
      color: #d32f2f;
      cursor: default;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    .buttons-wrapper {
      flex-direction: column;
      align-items: flex-end;

      button {
        margin-right: 0;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

      }
    }
  }

  .internship-details {
    display: flex;
    flex-direction: column;

    b {
      color: #333
    }
  }
}

.outside-of-internship-time-icon {
  svg {
    color: orange
  }
}

.desiderata-warning {
  svg {
    color: var(--primary-color)
  }
}

.color-red {
  color: #FF7F7F !important;
}

.color-border-red {
  border: solid 0.5px #FF7F7F;
}
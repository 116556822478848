.text-breakline{
  white-space: pre-wrap;
}

.table-options{
  margin-bottom: 10px;
}

.table-factory{
  thead tr th {
    font-weight: bold;
  }
}

.default-select{
  width: 150px;
}

.wrong-data{
  background-color: rgba(255, 0, 0, 0.5);
}
.recruitment-module-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .info-text {
    padding-bottom: 20px;
  }

  label {
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1.2px;
    color: #3c8dbc;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .questions-list {
      display: block !important;
    }
  }

  .questions-list {
    width: 100%;
    display: flex;
    padding: 10px 0 20px 0;

    .question-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      padding: 10px;
      margin: 5px;

      &.invalid {
        .question-text {
          color: #d32f2f;
        }
      }

      .question-options {
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        width: 80%;

        button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 24px;
          outline: none;

          &:disabled {
            cursor: default;
          }

          svg {
            color: #bbb;
          }

          &.negative-answer-selected {
            svg {
              color: #cf1322;
            }
          }

          &.medium-answer-selected {
            svg {
              color: #faad14;
            }
          }

          &.positive-answer-selected {
            svg {
              color: #389e0d;
            }
          }
        }
      }
    }
  }

  .comments-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 15px;

    textarea {
      margin-top: 5px;
      border: 1px solid #d9d9d9;
      width: 100%;
      outline: none;
      border-radius: 2px;
      padding: 4px 11px;
      height: 100px;
      resize: none;
      flex: 1;

      &::placeholder {
        color: #ccc;
      }
    }
  }

  .submit-feedback-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.space-between {
      justify-content: space-between;
    }

    .submit-error {
      font-weight: bold;
      color: #cf1322;
      cursor: default;
    }

    .previously-evaluated {
      font-weight: bold;
      color: #389e0d;
      cursor: default;
      display: flex;
      align-items: center;
      height: 32px;
    }
  }

  &.student-feedback {
    padding-top: 20px;

    .questions-list {
      justify-content: space-between;

      .question-item {
        max-width: 28%;
      }
    }

    .comments-wrapper {
      textarea {
        min-height: 150px;
      }
    }
  }
}

.evaluation-popconfirm {
  .ant-popover-message {
    max-width: 200px;
  }
}
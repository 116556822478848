.supervised-sectors-add-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 520px;

  .supervised-sectors-body {
    padding: 20px 20px 20px 20px;
    width: 100%;
    height: 467px;
    display: flex;
    flex-direction: row;

    .select-data-container {
      height: 100%;
      width: 50%;
      padding-right: 15px;
  
      .institution-data-container {
        margin-bottom: 10px;
      }

      .sectors-data-container {
        height: 305px;
      }
    }
  
    .selected-data-container {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 50%;
      padding-left: 15px;
      border-left: 1px solid #e8e8e8;

      .selected-data-list {
        width: 100%;
        overflow-y: auto;
      }
  
      .list-item-value {
        display: flex;
        flex-direction: row;
        align-items: center;
  
        svg {
          color: red;
        }

        span {
          font-size: 10px;
        }
  
        .ant-btn {
          margin-left: 5px;
          height: 18px;
          width: 18px;
          padding: 0px;
  
          .svg-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .supervised-sectors-footer {
    padding: 10px 25px 10px 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    border-top: 1px solid #e8e8e8;

    svg {
      margin-right: 5px;
    }
  }
}
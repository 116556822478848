.school-options {
  margin: 25px 0;
  width: 50%;
}

@media (max-width: 820px) {
  .school-options {
    width: 100%;
  }
}

.email-check-status {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;

  span {
    padding: 0 8px;
    font-size: 20px;

    svg {
      color: #bbb;
    }

    &.not-allowed {
      svg {
        color: #cf1322;
      }
    }

    &.check-highlighted {
      svg {
        color: #389e0d;
      }
    }
  }
}
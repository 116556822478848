.onMouseClickable{
  cursor: pointer;
}

.inputText {
  text-overflow: ellipsis;
}

.not-allowed{
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
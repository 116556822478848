.add-tag-form {
  display: flex;
  flex-direction: column;

  .row {
    padding: 15px 0;
    display: flex;
    flex-direction: column;

    &.columns {
      flex-direction: row;
      justify-content: space-between;
    }

    .column {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .icon-img {
        width: 100%;
      }
    }

    label {
      margin-bottom: 5px;
      opacity: 0.8;
    }

    &.names {
      input {
        margin-top: 15px;

        &:first-of-type {
          margin-top: 5px;
        }
      }
    }
  }

  .upload-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;

    svg {
      margin-bottom: 10px;
    }
  }
}

.school-modules-settings {
  height: 93vh;

  .loading-container {
    padding-bottom: 10%;
  }
}

.no-modules-settings {
  height: 93vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .ant-col {
    padding-top: 20%;
  }

  .no-modules-container {
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .modules-list {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 75%;
      max-width: 600px;
      margin-left: 20px;
  
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-left: 8px;
        margin-bottom: 20px;
        margin-top: 5px;
    
        .ant-checkbox-wrapper {
          margin-left: 0px;
        }
      }

      button {
        max-width: 200px;
      }
    }
  }
}

.modules-list {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 8px;
    margin-bottom: 20px;
    margin-top: 5px;

    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }

  button {
    max-width: 200px;
  }
}

@media only screen and (max-width: 750px) {
  .hidden-mobile {
    display: none !important;
  }

  .school-modules-settings {
    .no-modules-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 300px;
      overflow-x: auto;

      .modules-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}
$default-text-color: rgba(0, 0, 0, 0.65);
$border-color: #e8e8e8;
$selected-cell-color: rgb(60, 126, 188);
$table-header-row-size: 40px;
$table-header-color: #f5f5f5;
$table-places-size: 25px;
$table-row-size: 40px;
$table-first-cell-width: 250px;
$table-first-cell-max-width: 300px;
$table-cell-width: 15%;
$min-table-cell-width: 80px;
$min-table-cell-width-expanded: 120px;
$places-cell-color-ok: #B6DD82;
$places-cell-color-exhausted: #FFCF82;
$places-cell-color-exceeded: #FCA6A8;
$total-row-font-size: 12px;
$drawer-title-height: 55px;
$drawer-body-padding: 24px;
$global-card-height: 190px;
$managing-places-card-min-height: 240px;
$max-drawer-inner-body-height: calc(100vh - #{$drawer-title-height} - (2 * #{$drawer-body-padding}) - #{$global-card-height});

.places-definition-table-div {
  height: 100%;
  width: 100%;
  padding: 5px;

  .definition-table {
    display: block;
    max-height: 100%;
    min-height: calc(#{$table-row-size} * 3);
    overflow-x: auto;
    border-radius: 10px 10px 0px 0px;
  
    .definition-header-row {
      height: $table-header-row-size;
      border-bottom: 0px;
    }
  
    .definition-total-row {
      height: $table-places-size;
  
      td {
        font-weight: bold;
        font-size: $total-row-font-size;
        background-color: $table-header-color;
        position: sticky;
        top: $table-row-size;
        z-index: 1;
        
        &:first-child {
          color: $default-text-color;
          background-color: $table-header-color;
          z-index: 2;
        }
      }
    }

    tr {
      width: 100%;
      height: $table-row-size;
      border-bottom: 1px solid $border-color;
    }
  
    td {
      width: $table-cell-width;
      min-width: $min-table-cell-width;
      height: $table-row-size;
  
      &:first-child{
        width: $table-first-cell-width;
        min-width: $table-first-cell-width;
        max-width: $table-first-cell-max-width;
        height: 100%;
        padding: 10px 0px 10px 10px;

        position: sticky;
        left: 0px;
        z-index: 1;
  
        background-color: white;

        div {
          border-right: 1px solid $border-color;
          justify-content: left;
        }
      }

      &:not(:first-child) {
        padding: 5px 5px 5px 10px;
        cursor: pointer;
      }
    }
  
    th {
      min-width: $min-table-cell-width;
      height: 100%;
      padding-left: 10px;
      background-color: $table-header-color;
      position: sticky;
      top: 0px;
      z-index: 1;
      font-weight: bold;
      font-size: 14px;
  
      &:first-child{
        width: $table-first-cell-width;
        min-width: $table-first-cell-width;
        max-width: $table-first-cell-max-width;
        left: 0px;
        z-index: 2;
      }
    }
  }
}

.horizontal-margin-30 {
  border: 1px solid black;
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.vertical-center {
  flex-direction: row;
  align-items: center;
}

.places-creater-drawer-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  h3 {
    width: 100%;
  }
}

.expanded-cell-width {
  td:not(:first-child) {
    min-width: $min-table-cell-width-expanded;
  }
}

.selected-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $selected-cell-color;
  height: 100%;

  &:nth-child(2) {
    border-left: 2px solid $selected-cell-color;
  }
}

.sections-sub-cell {
  font-size: $total-row-font-size;

  .ant-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:first-child {
      align-items: stretch;
    }
  }

  .section-sub-cell-details {
    .ant-row {
      margin: 2px 5px 2px 5px;
      
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      .ant-col:first-child {
        padding-left: 5px;
      }

      .ant-col-18 {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .sections-sub-cell-total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-weight: bold;
    border-right: 1px solid $border-color;
  }
}

.regular-cell {
  padding: 2px 0px 2px 5px;
}

.places-managing-drawer-body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .places-managing-drawer-inner-body {
    min-height: $managing-places-card-min-height;
    max-height: $max-drawer-inner-body-height;
    padding: 15px;
    border: 1px solid $border-color;
    border-top: 0px;
    overflow-y: auto;
  }
}


.admin-reset-password-wrapper {
  .reset-password-description {
    color: #555;
    margin-bottom: 20px;
  }

  .ant-checkbox-wrapper {
    line-height: 22px;
  }
}

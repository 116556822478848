$interneo-header-heigh: 175px;
$option-width: 200px;
$search-input-height: 30px;
$option-selector-height: 250px;
$ant-tree-checkbox-width: 16px;
$border-color: #e8e8e8;
$list-item-padding: 5px;
$list-item-height: 20px;


.places-display-table{
  margin-top: 5px;

  thead tr th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px;
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
    border-top: 2px solid transparent;
    font-weight: bold;
    user-select: none;

    &:not(:first-child){
      text-align: center;
    }

    .table-column-label {
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80%;
    }
  }

  tbody {
    tr {
      border: 1px solid transparent;
      border-bottom: 1px solid #ececec;

      &.new-data {
        border: 1px solid #3c8dbc;
      }

      &:focus {
        border: 1px solid red;
      }

      &:nth-last-child(2){
        border-bottom: 0px solid transparent;
      }
    }

    td {
      padding-left: 3px;
      padding-right: 3px;
      width: 1%;

      &:first-child{
        max-width: 175px;
        min-width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
      }

      &:not(:first-child){
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: #cde9ff;
        }
      }
    }

    .sections-displaying{
      tbody {
        tr {
          border: 1px solid transparent;
        }
    
        td {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }

    #total-row{
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        height: 30px;

      td {
        cursor: default;
        font-weight: bold;
      
        div{
          font-weight: inherit;
        }

        &:hover {
          background-color: white;
        }
      }
    }
  }
}

.quota{
  width: 100%;
  height: calc(100vh - #{$interneo-header-heigh});
  display: flex;
  flex-direction: row;

  .definition-settings-div {
    height: 100%;
    max-width: 250px;
    min-width: 200px;
    width: 20% 
  }

  .options-and-table-div {
    height: 100%;
    width: 100%;
    min-width: 425px;
    padding: 5px;
    padding-left: 10px;
  }

  .quota-options{
    margin-right: 30px;
    max-height: 100%;
    width: $option-width;
  }
}

.options-selector{
  max-height: $option-selector-height;
  width: 100%;
  margin-top: 10px;

  .options-title{
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .options-tree{
    overflow-x:hidden;
    overflow-y: auto;

    .ant-tree{
      li{
        width: 100%;

        span.ant-tree-switcher{
          width: 0px;
        }

        .ant-tree-node-content-wrapper {
          width: calc(100% - #{$ant-tree-checkbox-width});
        }

        span.ant-tree-title{
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.quick-move-buttons{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 5px 0px 5px 10px;
  border-radius: 30px;

  background-color: #ececec;
  
  button{
    width: 20px;
    height: 20px;
    padding: 0px;
    margin: 0px 5px 0px 5px;
  }
}

.quick-popover-buttons-div{
  button{
    font-size: 10px;
    margin: 0px 2px 0px 2px;
    height: 19px;
    width: 50px;
    padding: 0px 5px 0px 5px;
    span{
      font-weight: bold;
    }
  }
}

.quota-drawer-flex-column{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 0px 10px;
}

.border-div{
  border: 1px solid lightgray;
  border-top: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.drawer-quota-form{
  min-height:100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-item-wrapper{
  display: flex;
  flex-direction: row;
  align-items:flex-start;
}

.center{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
}

.fill-space{
  height: 70%;
}

.standard-margin{
  margin: 5px;
}

button{
  .svg-inline--fa{
    vertical-align: -0.1em;
  }
}

.select-list-component{
  width: 100%;

  margin-bottom: 5px;

  .select-list-item-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    
    .list-item-clickable-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 5px;
    }
  
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }
  }

  .select-list-body {
    min-height: calc(#{$list-item-height} + 2*#{$list-item-padding});
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $border-color;
    border-top: 0px;
    padding: 5px;

    .ant-list-item {
      padding: $list-item-padding;
      height: calc(#{$list-item-height} + 2*#{$list-item-padding});
    }
  }
}

.definition-settings {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-right: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  overflow-y: auto;
}
.student-badge-quick-menu {
  position: relative;
}

.student-badge-quick-menu .ant-badge {
  left: -3px;
  position: relative;
  top: -9px;
  width: 0px;
}

.student-badge-quick-menu .ant-btn {
  margin-right: 10px;
}

.student-badge-quick-menu.no-margin .ant-btn {
  margin-right: 0;
}

.student-badge-quick-menu .ant-btn svg {
  font-size: inherit;
  margin-right: 0
}
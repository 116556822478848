.validate-internship-button {
  border-radius: 1000px;
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  margin-top: 6px;
  border: 1px solid #ccc;
  background: transparent;
  position: relative;
  display: flex;


  svg {
    color: rgb(3, 169, 244);
    font-size: 12px;
    font-weight: bold;
  }

  &.validated {
    background: rgb(126, 183, 62) !important; 

    svg {
      color: #fff
    }
  }

  .to-validate-notification {
    position: absolute;
    top: -10px;
    right: -3px;

    svg {
      color: #d32f2f;
      font-size: 12px;
    }
  }
}

.internship-validation-button-custom {
  padding: 5px;
  margin-left: 15px;

  button {
    margin-top: 0;
  }
}

.internship-sheet-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
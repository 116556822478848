.student-extra-contact-details {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;

  label {
    opacity: 0.6;
    margin-bottom: 16px;;
  }

  .separation-line{
    width:100%;
    height:1px;
    background: rgba(0,0,0,0.15);
    margin: 16px 0;
  }

  .item-row {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .ant-row {
      margin:0;
      flex:1;
      padding-right: 16px;

      .ant-form-explain {
        position: absolute;
      }
    }
  }

  .add-button-wrapper {
    display:flex;
    justify-content: flex-end;
  }
}
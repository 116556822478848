.hinted-switch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 30px;

    .switch-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;    
    }

    .icon-tooltip-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.hinted-switch-small {
    height: 16px;

    .icon-tooltip-div {
        margin-left: 4px;
    }
}

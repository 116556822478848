.send-email-form-container {
  label {
    display: flex;

    svg {
      margin-left: 5px;
      color: #3c8dbc;
    }
  }

  .ant-btn {  
    svg {
      margin-right: 5px;
    }
  }
}
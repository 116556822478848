.notification-body {
  display: flex;
  align-items: center;
  .loading-icon {
    margin: 0;
    margin-right: 10px;
  }
}

.button-has-validator {
  .ant-btn[disabled] {
    background: #7eb73e !important;
    border-color: #7eb73e !important;
    svg {
      color: #fff;
    }
  }
}

.select-sector {
  float: right;
  width: 300px;
}

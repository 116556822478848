.carpooling-region-modal {
  height: 400px;

  .carpooling-modal-region-container {
    height: 95%;
    overflow-y: auto  ;

    .carpooling-region-info {
      display: flex;
      flex-direction: row;
      border: 0px;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 5px;
  
      .ant-input {
        border-radius: 4px 0px 0px 4px;
      }
  
      .ant-btn {
        border-radius: 0px 4px 4px 0px;
        width: fit-content;
      }
    }
  }

  .ant-btn {
    width: 100%;
  }
}

.carpooling-region-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
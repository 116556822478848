.sections-selection-for-file {
  display: flex;
  flex-direction: column;

  .description {
    margin-bottom: 20px;
    color: #999;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    label {
      margin-bottom: 5px;
    }
  }

  button {
    margin-top: 20px;
  }

  .select-all-wrapper {
    padding-bottom: 5px;
    border-bottom: 1px solid #e9e9e9
  }
}
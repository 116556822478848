.act-type-school-sections-list-container {
  display: flex;
  flex-direction: column;

  .v-space {
    margin-bottom: 8px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 8px;
  }

  .ant-btn {
    svg {
      margin-right: 4px;
    }

    &:hover {
      background-color: green !important;
      border-color: green;
    }
  }
}
$border-color: #e8e8e8;

.constraints {
  margin-top: 20px;
  width: fit-content;
  height: 100%;
  width: 100%;

  .internship-constraints {
    width: 100%;
    min-width: 400px;
    
    .ant-card-head-title {
      white-space: initial;
      text-overflow: initial;
    }
  }
}
.additional-email-confirmation {
  display:flex;
  flex-direction: column;
  align-items: center;

  h2{
    margin-bottom:24px;
  }

  .loading-ring {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0;
    top: 0;
    left: 0;
    margin-bottom: 24px;
  }

  .confirmation-error {
    font-size:16px;
    color: #f44336;
    margin-bottom: 24px;
  }

  .confirmation-success {
    font-size:16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      color: rgb(126, 183, 62);
      font-size: 48px;
    }

    div {
      text-align: center;
      margin:24px 0;
    }

  }
  
}
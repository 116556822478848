table.internships-manager {
  width: 1px;
  border-collapse: collapse;
  margin-top: 5px;
  table-layout: fixed;

  thead tr th {
    top: 0;
    position: sticky;
    background: white;
    z-index: 1;
    text-align: center;

    &.is-current-week div {
      border-top: 2px solid #3c8dbc;
    }

    &.highlighted {
      background-color: #cde9ff;
    }

    &:not(.is-current-week) div {
      margin-top: 2px;
    }

    &.week {
      width: 43px;
      /* If this value is changed, it has to be also updated in the const of the InternshipsManager[s]. */
    }
  }

  tbody {
    td:nth-child(n + 3),
    td:nth-child(1) {
      border-bottom: 1px solid #ececec;
    }

    tr {
      height: 80px;

      &:nth-child(even) {
        background-color: #ececec;
      }

      td {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        padding: 0px;

        &.has-internship {
          & > div {
            text-align: center;
            height: 38px;
            border-top: 2px solid #3c8dbc;
            border-bottom: 2px solid #3c8dbc;
          }

          div.underloaded {
            background: #7eb73e;
          }

          div.balanced {
            background: #3c8dbc;
          }

          div.overloaded {
            background: #ff5722;
          }
        }

        & > div.actionnable {
          cursor: pointer;
        }

        div.actionnable {
          .fa-plus {
            margin-top: 11px;
          }

          .fa-trash {
            margin-top: 10px;
          }
        }

        &.has-internship div.actionnable {
          .fa-arrow-left {
            margin-top: 10px;
            margin-left: 15px;
          }

          .fa-arrow-right {
            margin-top: 10px;
            margin-right: 15px;
          }
        }

        &:not(.has-internship) div.actionnable {
          .fa-arrow-left {
            margin-top: 5px;
            margin-left: 15px;
          }

          .fa-arrow-right {
            margin-top: 5px;
            margin-right: 15px;
          }
        }

        .ant-select {
          width: 100%;
        }

        .ant-select.narrow {
          .ant-select-selection {
            height: 25px;
          }

          .ant-select-selection__rendered {
            line-height: 25px;
          }
        }

        &.has-internship.has-internship-end > div {
          border-right: 2px solid #3c8dbc;
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
        }

        &.has-internship.has-internship-start > div {
          border-left: 2px solid #3c8dbc;
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
        }
      }

      &:hover {
        td {
          background-color: #cde9ff;
        }
      }
    }
  }

  td > .ant-badge {
    left: -3px;
    position: relative;
    top: -9px;
    width: 0px;
  }
}

/* MENU & NOTIFICATIONS */
.ant-dropdown {
  svg {
    width: 24px !important;
  }

  .ant-badge {
    position: fixed;
    margin-top: -6px;
    margin-left: 17px;
  }
}

.sort-direction-button {
  display: flex;
  align-items: center;
  justify-content: center;

 .ant-btn {
    cursor: pointer;
    background: transparent;
    outline: none;
    padding: 0 11px;
    border-radius: 0px 4px 4px 0px;
    margin-right: 10px;
  }

  .ant-select-selection {
    border-radius: 4px 0px 0px 4px;
  }

  &.asc button {
    transform: rotate(90deg);
  }

  &.desc button {
    transform: rotate(270deg);
  }
}

.internship-student-year-wrapper {
  display: flex;
  align-items: center;

  .ant-select {
    &:first-child {
      width: 80%;
    }

    &:last-child {
      width: 20%;
    }
  }
}

.status-validation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .validate-internship-button {
    background: #fff;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.flex-center {
  width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#loading-td {
  display: block;
  width: 95vw;
  height: 69vh;
  background-color: white;

  div {
    width: 100%;
    height: 100%;
  }

  .table-loading-ring {
    position: relative;
    left: 50%;
    top: 25%;
    border: 5px solid transparent;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border-top-color: #3c8dbc;;
    border-bottom-color: #3c8dbc;;
    animation: loading-rotate .8s ease infinite;
  }
}
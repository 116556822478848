.text-color-red{
  color: red;
}

.free-fields-section{
  margin-top: 15px;
  padding: 15px 24px 24px 24px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.standard-top-margin{
  margin-top: 15px;
}

.information-displayer-container {
  width: 100%;

  .information-line {
    width: 100%;

    .flex-column {
      .schedule-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        svg {
          color: #0096FF;
        }

        .ant-btn {
          background-color: #F0FFFF;
          border: 1px solid #6495ED;

          span {
            margin-left: 5px;
            color: #0096FF;
          }
        }
      }
    }
  }
}
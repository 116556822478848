.internship-state-pellet-wrapper {
  .ant-select-selection>div {
    padding: 0;
    margin: 0;
  }

  .ant-select-selection-selected-value {
    width: 100%;
    display: flex !important;
    justify-content: center;
    height: 30px;
  }

  .internship-state-pellet-option {
    padding: 0 11px;
  }
}

.auto-accepted-border {
  .ant-select-selection {
    border: 2px solid #7eb73e;
  }
}

.internship-state-pellet-option {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  &.open {
    justify-content: space-between;
  }
}

.internship-state-pellet {
  display: flex;
  flex-direction: row;
  position: relative;

  .icon-container {
    display: flex;
    position: relative;
  }

  .primary-icon {
    height: 20px;
    width: 20px;
    border-radius: 9999px;
    font-size: 10px;
    display: inline-flex;
    color: white;
  }

  .secondary-icon {
    position: absolute;
    right: -8px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}
.easter-egg {
    animation: shake 1s, color 0.25s;
    animation-iteration-count: infinite; 
}

@keyframes color {
    0% { background-color: red; color: yellow; }
    25% { background-color: green; color: blue; }
    50% { background-color: yellow; color: red; }
    750% { background-color: blue; color: green; }
}
  
@keyframes shake {
    0% { transform: translate(15px, 15px) rotate(0deg) skewX(0deg); }
    10% { transform: translate(-15px, -30px) rotate(-3deg) skewX(3deg); }
    20% { transform: translate(-45px, 0px) rotate(3deg) skewX(0deg); }
    30% { transform: translate(15px, 30px) rotate(0deg) skewX(-3deg); }
    40% { transform: translate(15px, -15px) rotate(3deg) skewX(0deg); }
    50% { transform: translate(-15px, 30px) rotate(-3deg) skewX(3deg); }
    60% { transform: translate(-45px, 15px) rotate(0deg) skewX(0deg); }
    70% { transform: translate(45px, 15px) rotate(-3deg) skewX(-3deg); }
    80% { transform: translate(-15px, -15px) rotate(3deg) skewX(0deg); }
    90% { transform: translate(15px, 30px) rotate(0deg) skewX(3deg); }
    100% { transform: translate(15px, -30px) rotate(-3deg) skewX(0deg); }
}

@keyframes skew {
    0% { transform: skewX(5deg); }
    25% { transform: skewX(0deg); }
    50% { transform: skewX(-5deg); }
    80% { transform: skewX(0deg); }
}
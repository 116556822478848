@mixin vertical-flex {
  flex: 1;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}

.changelog-translations-drawer {
  .ant-drawer-wrapper-body {
    @include vertical-flex;

    .ant-drawer-body {
      @include vertical-flex;

      .changelog-translations-drawer-container {
        @include vertical-flex;

        .ant-tabs {
          @include vertical-flex;

          .ant-tabs-content {
            @include vertical-flex;

            .ant-tabs-tabpane-active {
              @include vertical-flex;

              .quill-text-editor {
                @include vertical-flex;
              }
            }
          }
        }

        .changelog-button-container {
          padding: 15px 0;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

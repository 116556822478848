.data-table-header {
  .ant-btn {
    margin-left: 5px;
  }
}

.data-table {
  height: 100%;
}

.data-table-container {
  height: 100%;

  .table-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;

    .table {
      margin-top: 10px;
  
      width: 100%;
    
      thead {
        th {
          font-weight: bold;
          border-bottom: solid 2px darkgray;
          padding-left: 10px;

          .data-table-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
              color: #0096FF;
              margin-right: 5px;
            }
          }
        }
      }
    
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: whitesmoke;
          }
          &:hover {
            td {
              background-color: #cde9ff !important;
            }
          }
        }
    
        td {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-bottom: 10px;
          padding-top: 10px;
          padding-left: 5px;
          padding-right: 5px;
          border-bottom: solid 1px lightgray;
        }
      }
    }
  }

  .loading-container {
    margin-top: 30px;
  }

  .data-table-footer {
    margin-top: 10px;
  }
}

.custom-overlay {
  .ant-dropdown-menu-item-group-title {
    color: #728aa3;
    background-color: #d8e1ed;
  }

  .ant-dropdown-menu {
    padding: 0px;
  }
}

.search-checkbox {
  height: 44%;
  min-height: 200px;
  margin: 10px 5px 10px 5px;

  .ant-input {
    border-radius: 4px 4px 0px 0px;
  }

  .search-checkbox-borders {
    height: calc(100% - 60px);
    border: 1px solid #d9d9d9;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .search-checkbox-borders-without-title {
    height: calc(100% - 32px);
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.search-checkbox-box {
  height: 100%;
  overflow-y: auto;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper:first-child {
    margin-left: 8px;
  }
}
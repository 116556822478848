.interactive-table{
  table{
    width: 100%;
    overflow: scroll;
    border-collapse: collapse;
    margin-top: 5px;
    table-layout: fixed;

    thead tr th {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      text-align: left;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 30px;
      padding-top: 3px;
      padding-left: 3px;
      padding-right: 3px;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      font-weight: bold;

      cursor: pointer;

      &.order-up {
        border-top: 2px solid #3c8dbc;
      }

      &.order-down {
        border-bottom: 2px solid #3c8dbc;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #ececec;

        &:first-child {
          border-top: 1px solid #ececec;
        }

        &:hover {
          background-color: #cde9ff;
        }

        &.new-data {
          border: 1px solid #3c8dbc;
        }

        &:focus {
          border: 1px solid red;
        }

        &.disable {
          border-bottom: none;
          background-color: #f5f5f5;

          td {
            border-bottom: 1px solid #ececec;

            &.buttons{
              background-color: white;
            }
          }
        }
      }

      td {
        padding-left: 3px;
        padding-right: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.incorrect-data {
          background-color: rgba(255, 0, 0, 0.5);

          input::placeholder {
            color: white;
          }
        }

        input {
          border: 0px;
          font-size: inherit;
          height: 30px;
          width: 100%;
          background: transparent;
          cursor: text;
        }

        .ant-select {
          width: 100%;

          .ant-select-selection {
            background: transparent;
            border: none;
            cursor: pointer;

            .ant-select-arrow {
              right: 0px;
            }
          }


        }

        span.ant-calendar-picker {
          width: 100%;

          i {
            display: none;
          }
        }
      }
    }
  }

  .footer {
    & > div {
      padding: 15px 3px 60px;
    }

    div {
      font-weight: bold;

      &.page-index {
        padding-top: 2px;
      }
    }
  }

  &.singleton-data {
    .hidden-sigleton-data {
      display: none !important;
    }

    table thead tr th {
      &:not(.actions) {
        cursor: inherit !important;
      }

      &.order-up > div {
        border-top: 2px solid transparent !important;
      }

      &.order-down > div {
        border-bottom: 2px solid transparent !important;
      }
    }
  }

}
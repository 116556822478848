.selection-custom-times {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 115px;
  margin-left: 0;
  transition: margin-left 0.2s ease-in-out;

  .input-block {
    display: flex;
    align-items: center;
    margin-right: 3px;

    label {
      margin-right: 5px;
      white-space: nowrap;
    }

    &.invalid {
      label {
        color: #d32f2f;
      }

      input {
        border-color: #d32f2f;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
  }
}

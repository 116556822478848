.student-information-container {
  .information-container {
    padding-left: 8px;
  }

  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  span {
    padding-left: 18px;
  }

  .school-information {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 15px;

    h3 {
      margin-top: 0px;
    }
  }

  .personal-information {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 15px;

    h3 {
      margin-top: 0px;
    }
  }
}
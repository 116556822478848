.students-import-modal-body {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;

  .upload-type-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    cursor: default;

    button {
      margin: 0 5px;
    }

    .selected {
      font-weight: bold;
      color: #000;
    }
  }

  h3 {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    color: #ff9800;

    &.displayed {
      max-height: 1000px;
    }
  }

  .upload-area {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;

    .importing-animation {
      display: flex;
      justify-content: center;
    }
  }

  .import-options {
    .ant-checkbox-group-item {
      display: flex;
      align-items: center;
    }
  }
}

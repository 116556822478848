tr.bordered {
    border-bottom: 1px solid #000 !important;
}

#col_all_contact_person, #col_all_local_administrator {
    text-align: center;
}

#col_all_title {
    padding-left: 15px;
}

td.buttons {
    float: right !important;
}

.button {
    margin-left: 2px;
    width: '28px';
    margin-top: 15%; 
}

.checkbox {
    width: 15px !important;
}

.div-checkbox {
    text-align: center;
}

.divCheckboxValidator {
    float: right;
}

.checkboxValidator {
    vertical-align: middle;
    margin: 5px;
}

.selectValidator {
    width: 70% !important;
}
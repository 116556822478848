.presets-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: block;
    .sector-shift-presets-container {
      display: flex;
      align-items: center;

      .preset-name {
        width: 180px;
        font-weight: bold;
        padding: 0 10px;
        flex-shrink: 0;
      }
  
      .presets-wrapper {
        padding: 0 10px;
  
        .preset-loading-wrapper {
          display: flex;
          align-items: center;
  
          .loading-icon {
            margin-right: 5px;
            margin-top: -1px
          }
        }
  
        .presets-codes-list {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }

  &>li:nth-child(even) {
    background-color: #ececec;
  }
}

.preset-card-header {
  display: flex;
  flex-direction: row;
  margin-right: 15px;

  .ant-select {
    width: 40px;

    .ant-select-selection {
      display: flex;
      justify-content: center;
    }

    &:last-child {
      min-width: 40px;
    }
  }
}

.shift-preset-container {
  width: 190px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-shrink: 0;
  border: 2px solid #ccc;
  border-radius: 5px;
  background: #fff;
  position: relative;
  margin: 5px 15px 5px 0;



  &.invalid {
    border: 2px solid red;

    &:first-child {
      border-left: 2px solid red;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 8px;
  }

  .label-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;

    input {
      width: 100%;
    }
  }

  .remove-preset-btn {
    position: absolute;
    background: transparent;
    border: none;
    top: 1px;
    right: 5px;
    cursor: pointer;
    font-size: 12px;
    opacity: 0.6;
    outline: none;
    padding: 0;

    &:hover {
      color: red;
      opacity: 1;
    }
  }

  .preset-code-input {
    border: 1;
    text-align: center;
    font-size: 18px;

    &:disabled {
      opacity: 0.5;
    }
  }

  .preset-time-wrapper {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    white-space: nowrap;

    .times-container {
      display:  flex;
      flex-direction: row;
      align-items: center;
      .ant-time-picker {
        max-width: 45%;
    
         &:first-child {
          margin-right: 4px;
         }
    
         &:last-child {
          margin-left: 4px;
         }
      }
    }
  }

  .preset-time-input {
    text-align: center;
    border: 0;
  }

  .preset-pause-wrapper {
    width: 100%;

    input {
      margin: 1px 0px;
      outline: none;
  
      &:hover {
        margin: 0px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }
    }
  }

  .color-selector-wrapper {
    width: 100%;
    display: flex;
    height: 26px;

    .selected-color {
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      flex: 1;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      outline: none;
      border: 1px solid #ddd;
      color: #3c8dbc;
      font-size: 12px;
    }

    .shift-preset-color-selector {
      position: absolute;
      top: 0;
      right: 0;

      .color-selector-cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.1);
        z-index: 3;
      }

      .color-selector-container {
        z-index: 4;
        position: absolute;
      }
    }
  }
}
.modal {
    .ant-modal-header {
        display: flex; 
        background-color: var(--main-color, #A3A3A4);
        margin: 10px auto 0;
        height: 70px;
        align-items: center; 
        justify-content: center;
        opacity: 0.7;
    }
    .ant-modal-title {
        align-self: center; 
        font-size: 20px;
        text-align: center;
        width: 100%; 
        color:  var(--text-color, 'white');
    }
    .subtitle{
        position: absolute;
        padding: 4px 20px;
        top: -25px;
        left: 50%; 
        background-color: var(--main-color, #A3A3A4);
        border-radius: 10px;
        color:  var(--text-color, 'white');
        opacity: 1;
        text-align: center; 
        display: flex; 
        justify-content: center; 
        align-items: center; 
        font-size: 20px;
        transform: translate(-50%, -50%);
    }
    .ant-modal-body{
        border-color: var(--main-color, #A3A3A4);
        border-left-width: 1px;
        border-left-style: solid;
        border-right-width: 1px;
        border-right-style: solid;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        
    }
 
}

.new-messages-notification-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0 4px;
  margin: 0;
  margin-left: 10px;

  svg {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .unread-messages {
    position: absolute;
    border-radius: 100px;
    background: red;
    color: #fff;
    font-weight: bold;
    padding: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    top: -10px;
    right: -20px;
  }

  &.small-text {
    .unread-messages {
      font-size: 8px;
    }
  }
}

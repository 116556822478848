.satisfaction-ratio {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;

  span {
    padding: 0 8px;
    font-size: 20px;

    &.tooltip-wrapper {
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;
    }

    svg {
      color: #bbb;
    }

    &.negative-highlighted {
      svg {
        color: #cf1322;
      }
    }

    &.medium-highlighted {
      svg {
        color: #faad14;
      }
    }

    &.positive-highlighted {
      svg {
        color: #389e0d;
      }
    }
  }

  .count {
    font-size: 14px;
    color: #bbb;
    font-weight: bold;
    cursor: default;
    position: absolute;
    right: -20px;
  }
}
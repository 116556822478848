.lazy-checkbox-container {
  height: 100%;
  width: 100%;

  .lazy-checkbox-body{
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    margin-left: 8px;
  }
}
.internship-extra-info-container {
  h3 {
    font-weight: bold;
  }

  .internship-badges {
    width: 100%;
    margin-bottom: 10px;

    .badge-details {
      width: 100%;
      display:  flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      margin-bottom: 10px;
      
      .numbers {
        width: 50%;
        display: flex;
        flex-direction: column;
      }

      svg {
        margin-right: 20px;
        color: #6495ED;
      }
    }

    .badge-details:last-child {
      margin-bottom: 20px;
    }
  }

  .internship-free-fields {
    .free-fields-container {
      margin: 0px 10px 10px 10px;
    }
  }
}
.switch-user-container {
  .switch-user-loading {
    width: 30%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    margin-right: 5px;
  
    svg {
      margin-left: 5px;
    }
  }
  
  .select-container {
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    b {
      width: 100px;
    }
  }
}
.internship-item-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .exclamation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 10%; 

    svg {
      color: rgb(249, 163, 109);
    }
  }
}
.conteneur {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.score-summary-card-large {
    width: 80%;
    border: solid 1px #08c4ec;
    height: 45%;
    margin: 10px;
    border-radius: 10px;
}

.score-summary-card-small {
    width: calc(40% - 10px);
    border: solid 1px #08c4ec;
    height: 45%;
    margin: 10px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    /* Ajustements pour les écrans plus petits */
    .score-summary-card-small {
        width: 80%;
        margin: 10px auto;
    }
}

.title-score-summary-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 15%;
    background-color: #08c4ec;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

.charts-score-summary-card {
    display: flex;
    justify-content: center;
    max-height: 50%;
}

.chart-score-summary-card {
    max-width: 35%;
    margin: 0 15%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    max-height: 100%;
}

.count-score-summary-card {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.evaluations-score-summary-card {
    display: flex;
    justify-content: center;
    height: 15%;
}

.evaluation-score-summary-card {
    margin: 0 10%;
    padding: 10px;
    text-align: center;
    max-height: 100%;
    display: flex;
    align-items: center;
}

.evaluation-score-summary-card span {
    margin: 5px;
    font-size: 17px;
}
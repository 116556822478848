.login-form {
  .labels {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .title {
      font-size: 24px;
      margin-bottom:10px;
    }

    .sub-title {
      display:flex;
      align-items: center;
      width:100%;
      font-size: 18px;

      .flex-fill {
        display:flex;
        align-items: center;

        &:first-child {
          padding-right: 5px;
          justify-content: flex-end;
        }
        &:last-child {
          padding-left: 5px;
        }
      }
    }
  }

  label {
    margin: 0;
    padding: 0;
    line-height: 20px;
    font-weight: bold;
    font-size: 18px;
    color:#000;
  }

  input {
    height: 40px;
    background-attachment: fixed;
  }

  .expired-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4d4f;

    svg {
      margin-right: 5px;
    }
  }

  .sign-in-wrapper {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .ant-form-item {
      margin-bottom: 0;
      .ant-checkbox-wrapper {
        display:flex;
        align-items: center;

        .ant-checkbox {
          position: initial;
        }

        .remember-text {
          font-size: 14px;
        }
      }
    }
  }
}
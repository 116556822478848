.form-item{
  margin-bottom: 1.5vh;
}

.form-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: #C0C0C0;
    font-size: 12px;
  }

  svg {
    margin-right: 8px;
    color: #3c8dbc;
  }
}

.form-footer {
  padding-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form-select {
  display: flex;
  flex-direction: column;
}

.form-alert {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.error-input-border {
  border-color: red !important;

  .ant-select-selection {
    border-color: red;
  }

  .ant-upload {
    border-color: red !important;
  }
}
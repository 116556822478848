.internship-to-evaluate-modal {
  .internship-container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .internship-info {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: left;
    font-size: 12px;
    color: #4682B4;


    span:first-child {
      font-weight: bold;
    }

    span:last-child {
      color: #A7C7E7;
      font-weight: bold;
    }
  }
  
  .internship-actions {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      padding-top: 3px;
      color: #0096ff;
      border: 1px solid #6495ed;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.thanks-message-container {
  display: flex;
  height: 150px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #009e60;


  .thanks-message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: bold;
  }

  svg {
    margin-right: 10px;
  }
}

@media (max-width: 769px) {
  .internship-to-evaluate-modal {
    .internship-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
  
    .internship-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items:flex-start;
      justify-content: left;
      font-size: 12px;
      color: #4682B4;
  
  
      span:first-child {
        font-weight: bold;
      }
  
      span:last-child {
        color: #A7C7E7;
        font-weight: bold;
      }
    }
    
    .internship-actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      button {
        width: 100%;
        padding-top: 3px;
        color: #0096ff;
        border: 1px solid #6495ed;
  
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .thanks-message-container {
    display: flex;
    height: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  
    .thanks-message {
      text-align: center;
    }
  
    svg {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}

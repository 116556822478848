$default_border_radius: 10px;
$default_line_border_padding: 10px;
$default_line_padding: 5px;
$default_border: 1px solid #e8e8e8;
$error_color: #FCA6A8;
$actions_width: 28px;
$actions_height: 24px;
$button_div_height: 40px;

.list-table-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  
  .table-div {
    width: 100%;
  }

  .adding-button-div {
    height: $button_div_height;
    width: 100%;
  
    .ant-btn {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-color: #e8e8e8;
  
      width: 100%;
      height: 100%;
    }
  }
}

.standard-line-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  .standard-table-header {
    height: 40px;
    width: 100%;
    background-color: #f5f5f5;

    th {
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      border-top: $default_border;
    }

    th:last-child {
      padding-right: $default_line_border_padding;
      border-top-right-radius: $default_border_radius;
      border-right: $default_border;
    }

    th:first-child {
      padding-left: $default_line_border_padding;
      border-top-left-radius: $default_border_radius;
      border-left: $default_border;
    }
  }

  .standard-table-body {
    .standard-table-line {
      td {
        padding-bottom: $default_line_padding;
        padding-top: $default_line_padding;
      }
      
      td:first-child {
        padding-left: $default_line_border_padding;
        border-left: $default_border;
      }
      
      td:last-child {
        border-right: $default_border;
        padding-right: $default_line_border_padding;

        .ant-btn {
          width: $actions_width;
          height: $actions_height;
          padding: 0px;
        }
      }

      .ant-select {
        width: 200px;
      }
    }

    .standard-table-line:first-child {
      td {
        padding-top: calc(2 * #{$default_line_padding});
      }
    }

    .standard-table-line:last-child {
      td {
        padding-bottom: calc(2 * #{$default_line_padding});
      }
    }

    .error-cell {
      background-color: $error_color;
    }
  }
}

.empty-list-table-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}
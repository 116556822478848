.notification-bell-container {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .number-bubble {
    position: relative;
    bottom: 6px;
    right: 6px;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}

.notification-item-container {
  display:  flex;
  flex-direction: row;
  align-items: center;

  .icon-div {
    width: 13%;
    display:  flex;
    flex-direction: row;
    align-items: center;
  }

  .text-div {
    font-size: 14px;
    font-style: italic;
    width: 87%;
  }
}
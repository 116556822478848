.evaluation-exports-body {
  .ant-card {
    .ant-card-body {
      padding: 0px;
    }

    .ant-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .evaluation-exports-filters-title {
        padding-top: 10px;
        padding-bottom: 2px;
        width: 100%;
        border-bottom: 1px #e8e8e8 solid;
        text-align: center;

        h4 {
          font-weight: bold;
        }
      }

      .evaluation-exports-filters-body {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
    
        button {
          cursor: pointer;
          background: none;
          border: none;
          outline: none;
          font-size: 20px;

          svg {
            color: #bbb;
          }
  
          &.negative-selected {
            svg {
              color: #cf1322;
            }
          }
  
          &.medium-selected {
            svg {
              color: #faad14;
            }
          }
  
          &.positive-selected {
            svg {
              color: #389e0d;
            }
          }
        }
      }
    }

    .ant-checkbox-group {
      padding-top: 0px;
    }
  }

  .data-sharing-filter {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    .ant-switch {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 2px;
      background-color: #7eb73e !important;
    }

    .ant-switch-checked {
      background-color: #f9a36d !important;
    }

    .orange-checked {
      color: #f9a36d;
    }

    .green-checked {
      color: #7eb73e;
    }

    svg {
      margin-bottom: 7px;
      margin-left: 4px;
    }

    span {
      font-weight: bold;
    }
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 15px;

    &:first-child {
      margin-top: 0px;
    }
  }
}
$default-text-color: rgba(0, 0, 0, 0.65);
$border-color: #e8e8e8;
$selected-cell-color: rgb(60, 126, 188);
$table-header-row-size: 40px;
$table-header-color: #f5f5f5;
$table-places-size: 25px;
$table-row-size: 40px;
$table-first-cell-width: 175px;
$table-cell-width: 20%;
$min-table-cell-width: 100px;
$places-cell-color-ok: #B6DD82;
$places-cell-color-exhausted: #FFCF82;
$places-cell-color-exceeded: #FCA6A8;

.allocation-quotas-table {
  display: block;
  max-height: 100%;
  min-height: calc(#{$table-row-size} * 3);
  overflow-x: auto;
  border-radius: 10px 10px 0px 0px;

  .allocation-table-header-row {
    width: 100%;
    height: $table-header-row-size;

    .allocation-table-header-cell:first-child {
      width: $table-first-cell-width;
      min-width: $table-first-cell-width;
    }

    .allocation-table-header-cell {
      min-width: $min-table-cell-width;
      height: 100%;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .allocation-table-places-row {
    width: 100%;
    height: $table-places-size;
    border-bottom: 1px solid $border-color;

    td {
      font-weight: bold;
      font-size: 12px;
      background-color: $table-header-color;
      position: sticky;
      top: $table-row-size;
      
      &:first-child {
        color: $default-text-color;
        background-color: $table-header-color;
        z-index: 2;
      }
    }

    .remaining-places-ok {
      background-color: $places-cell-color-ok;
    }

    .remaining-places-exhausted {
      background-color: $places-cell-color-exhausted;
    }

    .remaining-places-exceeded {
      background-color: $places-cell-color-exceeded;
    }
  }

  .allocation-table-row {
    width: 100%;
    height: $table-row-size;
    border-bottom: 1px solid $border-color;

    .allocation-table-first-cell {
      width: $table-first-cell-width;
      min-width: $table-first-cell-width;
      height: 100%;

      div {
        height: 100%;
        width: 100%;
        margin-right: 10px;
        border-right: 1px solid $border-color;
      }
    }

    .allocation-table-cell {
      min-width: $min-table-cell-width;
      height: 100%;
      cursor: pointer;
    }
  }

  td {
    padding-left: 10px;
    width: 5%;

    &:first-child{
      position: sticky;
      left: 0px;
      z-index: 1;

      background-color: white;
    }
  }

  th {
    padding-left: 10px;
    background-color: $table-header-color;
    position: sticky;
    top: 0px;
    z-index: 1;

    &:first-child{
      left: 0px;
      z-index: 2;
    }
  }
}

.allocate-quota-form {
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 20px 20px 0px 20px;

  .ant-row {
    margin-bottom: 10px;
  }
}

.allocation-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: right;

  .ant-btn {
    margin: 10px;
  }
}

.quotas-table-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;

  .actions-part {
    display: flex;
    flex-direction: row;
  }

  .navigation-part {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  }
}

.quota-modification-drawer-body {
  overflow-y: auto;
  padding: 15px;
}

.popover-icon-button {
  .ant-popover-title {
    font-weight: bold;
    text-align: center;
  }

  .ant-popover-inner-content {
    padding: 0px;
  }
}

.popover-icon-button-content {
  padding: 10px 20px 10px 20px;
}

.popover-icon-button-default-actions {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: center;
  border: 1px solid $border-color;

  .ant-btn {
    font-size: small;
    height: 24px;
    padding: 0px 10px;
  }

  .popover-icon-button-action {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }

  .popover-icon-button-action:not(:last-child) {
    border-right: 1px solid $border-color;
  }
}  

.allocation-table-selected-cell {
  border: 1px solid $selected-cell-color;

  &:nth-child(2) {
    border-left: 2px solid $selected-cell-color;
  }
}

.empty-quota {
  color: lightgray;
}

@media only screen and (max-width: 950px) {
  .quotas-table-menu {
    display: flex;
    flex-direction: column;
    padding: 5px;
  
    .navigation-part {
      justify-content: center;
      margin-top: 5px;
      margin-left: 0px;
    }

    .actions-part {
      display: flex;
      flex-direction: row;
      justify-content: center;

    }
  }  
}
.institution-group-view-container {
  svg {
    margin-right: 8px;
    color: #3c8dbc;
  }

  .actions-container {
    margin-top: 24px;
  }

  .ant-row {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}